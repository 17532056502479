<template>
<div>
      <Loader v-if="!avg"></Loader>
      <div v-else>
            <DRR :game="game"></DRR>
            <div class="mt-3">
                  <p class="font-italic text-center">
                        {{text}}
                  </p>
                  <p class="font-italic text-center">
                        Om du vill använda genomsnittstipset klickar du på knappen Använd
                  </p>
                  <div class="text-center">
                        <b-button @click="close()" variant="secondary" class="mx-1">Stäng/Avbryt</b-button>
                        <b-button @click="use()" variant="primary" class="mx-1">Använd</b-button>
                  </div>
            </div>
      </div>
</div>
</template>

<script>
const Loader = () => import("@/components/miscellaneous/Loader");
const DRR = () => import("@/components/games/DayResultRow");

export default {
      name: "GameAVG",
      props: ["avg"],
      components: {
            Loader,
            DRR
      },
      computed: {
            game() {
                  var g = this.avg.game;
                  g.homescore = Math.round(this.avg.avg[""].homescore);
                  g.awayscore = Math.round(this.avg.avg[""].awayscore);
                  g.result = Math.round(this.avg.avg[""].bet);
                  return g;
            },
            text() {
                  var winner = this.winner;
                  var game = this.game;
                  let text = "";
                  if (winner) {
                        text = this.avg.avg[""].betcount + " användare tror på seger för " + winner + " med " + game.homescore + " - " + game.awayscore;
                  } else {
                        text = this.avg.avg[""].betcount + " användare tror på likaresultat mellan " + this.hometeamname() + " och " + this.awayteamname();
                  }
                  return text;
            },
            winner() {
                  if (this.game.result == 1) {
                        // hemmaseger
                        return this.hometeamname();
                  } else if (this.game.result == 2) {
                        // bortaseger
                        return this.awayteamname();
                  }
                  return false;
            }
      },
      methods: {
            team(id) {
                  return this.$functions.getTeam(id);
            },
            hometeamname() {
                  var ht = this.team(this.game.hometeamid);
                  if (ht)
                        return ht.dispname;
                  return "hemmalaget";
            },
            awayteamname() {
                  var at = this.team(this.game.awayteamid);
                  if (at)
                        return at.dispname;
                  return "bortalaget";
            },
            close(){
                  this.$emit("close");
            },
            use(){
                  this.$emit("use", this.game);
            }
      }
}
</script>
